body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #C04BAF;
    background: -webkit-linear-gradient(top left, #C04BAF, #ECC04C);
    background: -moz-linear-gradient(top left, #C04BAF, #ECC04C);
    background: linear-gradient(to bottom right, #C04BAF, #ECC04C);
    background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}