.counter {
    margin: 20px;
    padding: 20px;
    border-radius: 20px;
    color: #fff;
    font-size: 18px;
    background: rgba(255,255,255,0.1);
    box-shadow: 10px 10px 20px -10px rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255,255,255,0.2);
    border-bottom: 1px solid rgba(255,255,255,0.2);
    backdrop-filter: blur(10px);
    transition: .5s ease;
    text-align: center;
}

.counter__button {
    background: #ECECEC;
    border-radius: 15px;
    padding: 10px 20px;
    display: block;
    font-family: arial;
    font-weight: bold;
    color:#7f7f7f;
    text-decoration: none;
    text-shadow:0px 1px 0px #fff;
    border:1px solid #a7a7a7;
    width: 145px;
    margin:0px auto;
    margin-bottom: 10px;
    box-shadow: 0px 2px 1px white inset, 0px -2px 8px white, 0px 2px 5px rgba(0, 0, 0, 0.1), 0px 8px 10px rgba(0, 0, 0, 0.1);
        -webkit-transition:box-shadow 0.5s;

    &:hover{
    box-shadow: 0px 2px 1px white inset, 0px -2px 20px white, 0px 2px 5px rgba(0, 0, 0, 0.1), 0px 8px 10px rgba(0, 0, 0, 0.1);
    }
    &:active{
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5) inset, 0px -2px 20px white, 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);
    background:-webkit-linear-gradient(top, #d1d1d1 0%,#ECECEC 100%);
    }
}