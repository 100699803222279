.second-page__wrapper {
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
        height: 100%;
    }
}

.second-page__return-btn {
    font-weight: bold;
    text-align: center;
    margin-left: 20px;
    background: #ECECEC;
    border-radius: 15px;
    padding: 10px 20px;
    display: block;
    font-family: arial;
    font-weight: bold;
    color:#7f7f7f;
    text-decoration: none;
    text-shadow:0px 1px 0px #fff;
    border:1px solid #a7a7a7;
    width: 145px;
    margin-top: 30px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 1px white inset, 0px -2px 8px white, 0px 2px 5px rgba(0, 0, 0, 0.1), 0px 8px 10px rgba(0, 0, 0, 0.1);
        -webkit-transition:box-shadow 0.5s;

    &:hover{
    box-shadow: 0px 2px 1px white inset, 0px -2px 20px white, 0px 2px 5px rgba(0, 0, 0, 0.1), 0px 8px 10px rgba(0, 0, 0, 0.1);
    }
    &:active{
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5) inset, 0px -2px 20px white, 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);
    background:-webkit-linear-gradient(top, #d1d1d1 0%,#ECECEC 100%);
    }
}